import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Furniture Store App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/furniture-store-mobile-app/"
    metaDescription="Working on an online furniture store app development project? Fast-track the design element of your project with our furniture store app template."
    description="
    h2:Furni Store: A furniture store app design template for mobile
    <br/>
    Working on an online furniture store app development project? Fast-track the design element of your project with our furniture store app template. Furni Store comes with everything your design could need from predefined user flows and all the UX elements you could need. Uizard <a:https://uizard.io/templates/>UI templates</a> do the hard work so you don't have to.
    <br/>
    h3:App mockup customization made easy with Uizard
    <br/>
    <a:https://uizard.io/ux-design/>App UX design</a> just got a whole lot easier with our brand-new range of app templates. All our app templates come with everything you could possibly need to bring your own furniture app design to life. Simply sign up for Uizard Pro, select the furniture app template, and customize it to suit your brand vision.
    <br/>
    h3:Work with your team like never before
    <br/>
    Invite your team to collaborate or share your design with stakeholders to get vital feedback on your app project, the choice is yours. Join the magical digital product design revolution with Uizard today and explore our stunning range of app templates.
    "
    pages={[
      "A stunning furniture store landing page with categories and CTAs",
      "Furniture store product listing page screen mockup",
      "Furniture store product display page screen mockup",
      "Customer reviews mockup screen (plus much more)",
    ]}
    projectCode="eJanqYAnLoSjL3n1mLOw"
    img1={data.image1.childImageSharp}
    img1alt="furniture store app design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="furniture store app design template item search screen"
    img3={data.image3.childImageSharp}
    img3alt="furniture store app design template item review screen"
    img4={data.image4.childImageSharp}
    img4alt="furniture store app design template checkout screen"
    img5={data.image5.childImageSharp}
    img5alt="furniture store app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/furniture-store-mobile-app/furniture-store-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/furniture-store-mobile-app/furniture-store-mobile-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/furniture-store-mobile-app/furniture-store-mobile-reviews.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/furniture-store-mobile-app/furniture-store-mobile-checkout.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/furniture-store-mobile-app/furniture-store-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
